import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationUtilityService } from '../../modules/shared/utils/validation.util/validation-utility.service';
import { AuthService } from '../../services/core/auth.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SpinnerComponent } from '../../modules/shared/spinner/spinner.component';
import { AppUtil } from '../../utils/app.util';
import { JwtHelper } from '../../services/core/jwt-helper.service';
import { Subscription, catchError, switchMap, throwError } from 'rxjs';
import { AnalyticsBean, EventTrackingBean, HvaTrackingBean } from '../../beans/analytics/analytics-module.bean';
import { Config } from '../../config/config';
import { EHEAndMeAuthService } from '../../services/core/eheandme-auth.service';
import { ProfileRelatedInfoBean } from '../../beans/account/account-module.bean';
import { ProfileService } from '../../services/core/profile.service';
import { MigrationStatusService } from '../../services/migration/migration-status.service';
import { MASK_CONSTANTS } from 'src/app/constants/mask.constants';
import { FeedsService } from '../../services/dashboard/feeds.service';
import { AppointmentsService } from '../../services/scheduling/appointment/appointments.service';
import { DataCarrierService } from '../../services/core/data-carrier.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { HVA_EVENT_TYPE, HVA_EVENT_STATUS } from '../../constants/analytics.constants';
import { PromoCodeUtilsService } from 'src/app/services/scheduling/promo-code-utils/promo-code-utils.service';
import { INCENTIVE_CONSTANTS } from 'src/app/constants/scheduling.constants';
import { ModalService } from 'src/app/services/core/modal.service';
import { MfaService } from 'src/app/services/core/mfa.service';
import { MfaSetupState, MfaUiCommands } from 'src/app/modules/shared/types';

interface CredentialsForm {
	email: string;
	password: string;
}

/**
 * Login Component
 */
@Component({
	selector: 'app-login-simplified',
	templateUrl: './login-simplified.component.html',
	styleUrls: ['./login-simplified.component.scss'],
})
export class LoginSimplifiedComponent implements OnInit, OnDestroy {
	/** Sign In form */
	signInForm: FormGroup;
	/** Booking Simplified Form */
	bookingSimplified: FormGroup;
	/** Default tab on load */
	defaultTab = 0;
	bookIsOrder = false;
	/** Booking Simplified Error counter/message */
	bsErrorCount = 0;
	bsErrorResponse = '';
	bsSecondError = false;
	/** Date Format */
	dateFormat: string = ValidationUtilityService.dateFormat;
	/** shorthand reference to dateOfBirth */
	dateOfBirth: FormControl;
	showHint = true;
	dobMask = MASK_CONSTANTS.DATE as any;
	isPulseVirtual = false;
	/**
	 * Associated with create account link in Login page,
	 * default value - /registration
	 * in the case of Marketo  - /registration/embed/signup
	 */
	createAccountUrl = '/registration';
	/**
	 * Associated with create account link in Login page,
	 * default value - /forgot/password
	 * in the case of Marketo  - /embed/forgot-password
	 */
	forgotPasswordUrl = '/forgot/password';
	/** query param value denote where to return after login */
	returnUrl: any = '';
	/**
	 * List of subscriptions used in the component
	 * and this will unsubscribed on destroy
	 */
	subscriptions: Subscription[] = [];
	/**
	 *  url to redirect after clicking forgot username
	 */
	forgotUsernameUrl = '';
	/**
	 * flag to display error message or not
	 */
	displayErrMsg = false;
	/** Custom Analytics data for the component */
	analyticsData: AnalyticsBean;
	signInMessage: any;
	eventData: EventTrackingBean;
	/** flag to show if profile not found error */
	profileNotFound: boolean = false;
	hvaEvent: HvaTrackingBean;
	// Store the error message
	errMessage: string = '';
	@Input() externalError: string;

	displayMfaInputScreen: boolean;

	/**
	 * Login component constructor
	 * @param authService Cognito related authentication service
	 * @param router Angular router
	 * @param spinner spinner Service
	 * @param activatedParams Angular activate route
	 * @param jwtService JWT Services
	 */
	constructor(
		private authService: AuthService,
		private router: Router,
		private spinner: SpinnerComponent,
		private activatedParams: ActivatedRoute,
		private jwtService: JwtHelper,
		private config: Config,
		private eheandmeAuthService: EHEAndMeAuthService,
		private profileService: ProfileService,
		private migrationService: MigrationStatusService,
		private feedsService: FeedsService,
		private appointmentService: AppointmentsService,
		private dataCarrierService: DataCarrierService,
		private analyticsService: AnalyticsService,
		private readonly promoUtilsService: PromoCodeUtilsService,
		private modalService: ModalService,
		private mfaService: MfaService
	) {
		this.analyticsData = new AnalyticsBean();
		this.eventData = new EventTrackingBean();
		this.analyticsData.componentName = 'login';
		this.analyticsData.pageUrl = '/login';
		// Restricting user to use back browser button
		this.subscriptions.push(
			this.activatedParams.queryParams.subscribe(params => {
				console.log('queryParams:', params);
				/** getting the return URL from params */
				this.returnUrl = params.returnUrl ? decodeURI(params.returnUrl) : '';
				if (sessionStorage.getItem('returnUrlOverride')) {
					this.returnUrl = sessionStorage.getItem('returnUrlOverride');
				}
				this.defaultTab = params.t ? JSON.parse(atob(params.t)) : this.defaultTab;
				if (params.clearHistory) {
					window.onpopstate = function (event) {
						history.go(1);
					};
				}
				if (params.tab) {
					if (params.tab === 'login') {
						this.defaultTab = 1;
						if (params.showErrorMsg) {
							this.profileNotFound = true;
						}
					} else if (params.tab === 'book') {
						this.defaultTab = 0;
						this.bookIsOrder = false;
					} else if (params.tab === 'order') {
						this.defaultTab = 0;
						this.bookIsOrder = true;
					}
				}
				if (this.returnUrl.includes('kitid')) {
					this.defaultTab = 1;
				}
				if (this.returnUrl.includes('tab=login')) {
					this.defaultTab = 1;
					this.returnUrl = this.returnUrl.replace('tab=login', '');
				} else if (this.returnUrl.includes('tab=book')) {
					this.defaultTab = 0;
					this.bookIsOrder = false;
					this.returnUrl = this.returnUrl.replace('tab=book', '');
				} else if (this.returnUrl.includes('tab=order')) {
					this.defaultTab = 0;
					this.bookIsOrder = true;
					this.returnUrl = this.returnUrl.replace('tab=order', '');
				} else if (this.returnUrl === '/assessment/info') {
					this.defaultTab = 1;
				}
			})
		);
		this.hvaEvent = new HvaTrackingBean();
	}

	/**
	 * initializing the component and
	 * create the login form with email and password fields
	 */
	ngOnInit() {
		this.signInForm = new FormGroup({
			email: new FormControl(
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(2),
					ValidationUtilityService.cognitoUsernameLengthValidator,
					Validators.maxLength(80), // hard limit of cognito for emails
				])
			),
			password: new FormControl('', Validators.compose([Validators.required])),
		});

		this.subscriptions.push(
			this.signInForm.get('password').valueChanges.subscribe(value => {
				if (this.signInForm.get('password').errors) {
					this.signInForm.get('password').setErrors({ passwordRequired: true });
				}
			})
		);

		this.dateOfBirth = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				ValidationUtilityService.dateValidator,
				ValidationUtilityService.dateValidatorNonFuture,
			])
		);

		this.bookingSimplified = new FormGroup({
			firstName: new FormControl('', Validators.compose([Validators.required])),
			lastName: new FormControl('', Validators.compose([Validators.required])),
			dateOfBirth: this.dateOfBirth,
		});

		this.subscriptions.push(
			this.dateOfBirth.valueChanges.subscribe(value => {
				// auto-zero pad opening month and day as a convenience, if it starts with 2-9
				ValidationUtilityService.zeroPadDateControl(<FormControl>this.bookingSimplified.get('dateOfBirth'), value);
			})
		);

		/**
		 * if the page is loading inside marketo the createAccountUrl and forgotPasswordUrl
		 * have to update to their corresponding marketo route
		 */
		if (this.router.routerState.snapshot.url.includes('embed')) {
			this.createAccountUrl = '/registration/embed/signup';
			this.forgotPasswordUrl = '/embed/forgot-password';
		}

		/** if there is a redirect url present that is not for scheduling, OR the user came from an SSO page set the default tab to sign in */
		if (this.returnUrl && ['login'].includes(this.returnUrl)) {
			this.defaultTab = 1;
		}

		this.subscriptions.push(
			this.mfaService.displayMfaInputScreen.subscribe(uiCommand => {
				console.info(`login-simplified.component.ts -> displayMfaInputScreen:`, uiCommand);
				this.displayMfaInputScreen = uiCommand === MfaUiCommands.SHOW;
				// let isLoggedIn = false;
				// if (uiCommand === MfaUiCommands.HIDE) {
				// 	isLoggedIn = !!this.jwtService.getDecodedToken();
				// 	console.log('isLoggedIn?:', isLoggedIn);
				// 	if (!isLoggedIn) {
				// 		const queryParams = { queryParams: { tab: 'login' } };
				// 		this.router.navigate(['/login'], queryParams);
				// 	}
				// }
				// if (!isLoggedIn) {
				// 	this.displayMfaInputScreen = uiCommand === MfaUiCommands.SHOW;
				// }
			}),
		);
	}

	/**
	 * login to the application
	 */
	async login() {
		console.info('in simplified component');
		this.externalError = null;
		this.analyticsData.id = 'login';
		this.analyticsData.redirectedTo = 'dashboard';
		this.analyticsData.placement = 'login screen';
		if (this.signInForm.valid) {
			const authCredentials: CredentialsForm = this.signInForm.getRawValue();
			this.spinner.startLoader();
			this.displayErrMsg = false;
			this.profileNotFound = false;
			authCredentials.email = AppUtil.getFormattedEmail(authCredentials.email);
			if (AppUtil.isValidEmail(authCredentials.email)) {
				await this.mfaService.fetchUserMfaActivatedStatus(authCredentials.email);
				this.authService.login(authCredentials.email, authCredentials.password);
				let loginSubscription = this.authService.getResult().subscribe({
					next: response => {
						if (!loginSubscription) { return; }
						loginSubscription.unsubscribe();
						this.handleLoginResponse(response, authCredentials);
					}, error: error => {
						if (!loginSubscription) { return; }
						loginSubscription.unsubscribe();
						this.handleLoginError(error, authCredentials)
					}
				});
				this.subscriptions.push(loginSubscription);
			} else {
				console.info('ELSE - calling attemptEheAndMeAuth()',);
				// EHE+Me Auth
				this.attemptEheAndMeAuth(authCredentials);
			}
		}
	}

	handleLoginResponse(response, authCredentials: CredentialsForm) {
		console.info('login-simplified.component.ts -> loginSubscription.response:', response);
		if (!response) { return };

		if (this.mfaService.ss_getMfaActivatedAt() === MfaSetupState.FIRST_TIME) {
			const subscription = this.profileService.setMfaActivatedStatus(authCredentials.email).subscribe({
				next: async (response: any) => {
					if (response) {
						this.mfaService.ss_setMfaActivatedAt(MfaSetupState.ALREADY_SET);
						this.mfaService.setMfaActivatedAt(MfaSetupState.ALREADY_SET);
					}
					subscription.unsubscribe();
				},
				error: error => {
					console.error(`[${AuthService.name}][${this.login.name}]`, error);
					subscription.unsubscribe();
				},
			});
		}
		// this.signInForm.get('email').setValue(emailMask(this.signInForm.get('email').value));
		this.mfaService.toggleDisplayMfaInputScreen(MfaUiCommands.HIDE);
		this.spinner.stopLoader();
		this.hvaEvent.type = HVA_EVENT_TYPE.SIGN_IN;
		this.hvaEvent.status = HVA_EVENT_STATUS.SUCCESS;
		this.hvaEvent.userID = this.jwtService.getEpmsId();
		this.analyticsService.sendHvaEvent(this.hvaEvent);
		this.getLoginStatus();

		const returnUrlOverride = sessionStorage.getItem('returnUrlOverride');
		console.log('returnUrl:', this.returnUrl);
		console.log('returnUrlOverride:', returnUrlOverride);

		/**
		 * handling the cases of marketo
		 * if the action requested from marketo, loading the page to full window
		 * otherwise loading to return url or by default dashboard
		 */
		if (this.router.routerState.snapshot.url.includes('embed')) {
			window.open(`${window.location.origin}/dashboard`, '_top');
		} else {
			if (returnUrlOverride) {
				this.returnUrl = returnUrlOverride;
			}
			if (this.returnUrl?.trim()?.length) {
				this.spinner.startLoader();
				this.profileService.getProfileInfo().subscribe({
					next: (response: ProfileRelatedInfoBean) => {
						this.spinner.stopLoader();
						if (returnUrlOverride) {
							this.returnUrl = returnUrlOverride;
						}
						const profileInfo = response;
						this.dataCarrierService.pushValue('profile', profileInfo);
						if (profileInfo.profileInfo.userMatched) {
							if (this.returnUrl.includes('scheduling/create/appointment')) {
								sessionStorage.setItem('RETURN_URL', 'true');
							}
							console.log('returnUrl final:', this.returnUrl);
							this.router.navigateByUrl(this.returnUrl);
							sessionStorage.removeItem('returnUrlOverride');
						} else {
							this.router.navigate(['dashboard']);
						}
					},
					error: err => {
						this.spinner.stopLoader();
					}
				});
			} else {
				this.spinner.startLoader();
				this.subscriptions.push(
					this.activatedParams.queryParams.subscribe(params => {
						if (params.from === 'vax-pass') {
							this.router.navigate(['/results'], {
								queryParams: { tab: 'vax-pass' },
							});
						} else {
							AppUtil.redirectToScheduling(
								this.router,
								this.feedsService,
								this.appointmentService,
								this.dataCarrierService,
								// sending promo code / referral code if present in url
								this.promoUtilsService.pullRequiredCodesForPromoAndReferral(
									this.activatedParams.snapshot.queryParams
								)
							);
						}
					})
				);
			}
		}
	}

	handleLoginError(error, authCredentials) {
		console.info('login-simplified.component.ts -> loginSubscription.error:', error);
		if (!error) { return; }

		this.hvaEvent.type = HVA_EVENT_TYPE.SIGN_IN;
		this.hvaEvent.status = HVA_EVENT_STATUS.FAIl;
		this.analyticsService.sendHvaEvent(this.hvaEvent);
		console.error(
			`[${LoginSimplifiedComponent.name}][${this.login.name}]`,
			'Error on login ',
			authCredentials?.email,
			error?.code,
			error?.message,
			error?.name,
			error
		);
		this.errMessage =
			error.message === this.config.exceptions.cognito.ATTEMPTS_EXCEEDED &&
			this.config.exceptions.cognito.ATTEMPTS_EXCEEDED;
		this.errMessage = error.name == 'LimitExceededException' ? 'Maximum attempts exceeded' : this.errMessage;
		if (error.code === this.config.exceptions.cognito.NOT_AUTHORIZED) {
			// Check migration status of the user when email/password combination is wrong
			this.checkMigrationStatus(authCredentials.email);
		} else {
			console.info('Cognito ERROR - calling attemptEheAndMeAuth()',);
			this.attemptEheAndMeAuth(authCredentials);
		}
	}

	tabChange(event) {
		this.defaultTab = event.index;
		this.eventData.eventCategory = 'Link';
		if (this.defaultTab === 0) {
			this.eventData.eventAction = 'Click-BookYourExamTab';
			this.eventData.eventLabel = 'View - Book Your Exam Tab';
		} else {
			this.eventData.eventAction = 'Click-SigninTab';
			this.eventData.eventLabel = 'Signin - Signin Tab';
		}
	}
	loadBookingSimplified() {
		if (this.bookingSimplified.valid) {
			const bsValues = this.bookingSimplified.getRawValue();
			this.spinner.startLoader();
			this.profileService
				.loadBookingSimplified(bsValues)
				.subscribe(
					response => {
						this.hvaEvent.type = HVA_EVENT_TYPE.BOOK_EXAM;
						this.hvaEvent.status = HVA_EVENT_STATUS.SUCCESS;
						this.analyticsService.sendHvaEvent(this.hvaEvent);
						if (response.eligibleForBooking === false) {
							this.defaultTab = 1;
							if (response.message === 'PE scheduled') {
								this.signInMessage =
									'You have a pending exam. Please sign in to complete your Health Assessment and review exam prep information.';
							} else {
								this.displayErrMsg = true;
							}
							this.spinner.stopLoader();
						} else if (response.token) {
							sessionStorage.setItem('nonEmail', 'true');
							const queryParams = {
								u: response.token,
								...this.activatedParams.snapshot.queryParams,
							};
							// only check, whether user came from /scheduling route, if yes, they are bound to navigate to /book with same query params
							this.updateQueryForBookNavigation(queryParams);
							if (this.isPulseVirtual) {
								this.router.navigate(['/pulse-virtual'], { queryParams });
							} else {
								this.router.navigate(['/book'], { queryParams });
							}
						} else {
							throw throwError(response);
						}
					},
					error => {
						this.hvaEvent.type = HVA_EVENT_TYPE.BOOK_EXAM;
						this.hvaEvent.status = HVA_EVENT_STATUS.FAIl;
						this.analyticsService.sendHvaEvent(this.hvaEvent);
						this.bsErrorCount++;
						this.displayErrMsg = true;
						if (this.bsErrorCount === 1) {
							this.bsErrorResponse =
								'We could not confirm your eligibility to book a new exam. Please try again or call 888.672.8172 to book your exam.';
						}
						if (this.bsErrorCount === 2) {
							this.bsSecondError = true;
							this.displayErrMsg = false;
						}
						if (this.bsErrorCount > 2) {
							this.defaultTab = 1;
						}
						this.spinner.stopLoader();
					}
				);
		} else {
			this.showHint = this.bookingSimplified.get('dateOfBirth').errors ? false : true;
		}
	}

	updateQueryForBookNavigation(queryParams: any) {
		if (
			'returnUrl' in queryParams &&
			(queryParams['returnUrl'].includes(INCENTIVE_CONSTANTS.PROMO_QUERY_KEY) ||
				queryParams['returnUrl'].includes(INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY)) &&
			queryParams['returnUrl'].includes('scheduling')
		) {
			const parsedURL = new URL(queryParams['returnUrl'], window.location.origin);
			const promoCode = parsedURL.searchParams.get(INCENTIVE_CONSTANTS.PROMO_QUERY_KEY)
				? parsedURL.searchParams.get(INCENTIVE_CONSTANTS.PROMO_QUERY_KEY)
				: null;
			const referralCode = parsedURL.searchParams.get(INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY)
				? parsedURL.searchParams.get(INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY)
				: null;
			if (promoCode) {
				queryParams[INCENTIVE_CONSTANTS.PROMO_QUERY_KEY] = promoCode;
			}
			if (referralCode) {
				queryParams[INCENTIVE_CONSTANTS.REFERRAL_QUERY_KEY] = referralCode;
			}
			delete queryParams['returnUrl'];
		} else if ('returnUrl' in queryParams && queryParams['returnUrl'].includes('pulse-virtual')) {
			this.isPulseVirtual = true;
			delete queryParams['returnUrl'];
		}
	}

	changeHint() {
		this.showHint = this.bookingSimplified.get('dateOfBirth').errors ? false : true;
	}

	redirect(url: string): void {
		if (this.returnUrl !== '') {
			this.router.navigate([url], {
				queryParams: { returnUrl: this.returnUrl },
			});
		} else {
			this.router.navigate([url]);
		}
	}

	attemptEheAndMeAuth(authCredentials: CredentialsForm) {
		this.eheandmeAuthService.eheandmeLogin(authCredentials.email, authCredentials.password).subscribe(
			res => {
				this.spinner.stopLoader();
				this.checkMigrationStatus(authCredentials.email);
			},
			err => {
				console.error(
					`[${LoginSimplifiedComponent.name}][${this.attemptEheAndMeAuth.name}]`,
					'An error occurred while authenticating ',
					err
				);
				this.spinner.stopLoader();
				this.displayErrMsg = true;
			}
		);
	}

	/**
	 * Checks the migration status of the user
	 * @param user - username/email
	 * @param navigate - flag to decide if user has to be redirected to ehe+me
	 */
	checkMigrationStatus(user: any = '') {
		this.spinner.startLoader();
		// EHE+Me Login succeeded, checking migration status of the user
		this.migrationService.getMigrationStatus(user).subscribe(
			response => {
				this.spinner.stopLoader();
				if (response.migrationStatus === 'Initiated') {
					// Initialized - Email exists on file - collect code and complete migration process
					const navigationExtras: NavigationExtras = {
						queryParams: {
							user: btoa(JSON.stringify(response)),
							returnUrl: this.returnUrl,
						},
					};
					this.router.navigate(['/migration/password'], navigationExtras);
				} else if (response.migrationStatus === 'Failed') {
					// Failed - No email on file - get email and complete migration process
					const navigationExtras: NavigationExtras = {
						queryParams: {
							user: btoa(JSON.stringify(response)),
							returnUrl: this.returnUrl,
						},
					};
					this.router.navigate(['/migration/email'], navigationExtras);
				} else if (response.migrationStatus === 'Completed') {
					// Completed - account has been migrated to myEHE
					this.displayErrMsg = true;
				} else {
					this.spinner.stopLoader();
					this.displayErrMsg = true;
				}
			},
			err => {
				this.spinner.stopLoader();
				this.displayErrMsg = true;
				console.error(
					`[${LoginSimplifiedComponent.name}][${this.checkMigrationStatus.name}]`,
					'An error occurred while fetching migration status of the user ',
					err
				);
			}
		);
	}

	/**
	 * Either shows/hides password hint
	 */
	showPasswordHint() {
		if (this.signInForm.get('password').touched && this.signInForm.get('password').errors) {
			return false;
		} else if (
			this.signInForm.touched &&
			!this.signInForm.get('password').touched &&
			this.signInForm.get('password').errors
		) {
			this.signInForm.get('password').setErrors({ passwordRequired: true });
			return false;
		}
		return true;
	}

	getLoginStatus() {
		if (window[this.config.userLoginSession.sessionCheckIntervalVarName]) {
			clearInterval(window[this.config.userLoginSession.sessionCheckIntervalVarName]);
		}
		window[this.config.userLoginSession.sessionCheckIntervalVarName] = setInterval(() => {
			this.profileService.GetSessionInfo().subscribe(res => {
				if (res.message === 'Session Closed') {
					this.modalService.openSessionExpireModal(
						'SESSION EXPIRED',
						'You have an active session in another device. Please login to continue'
					);
					this.profileService.inactivateSession().subscribe(response => this.authService.logoutUser());
				}
			});
		}, this.config.userLoginSession.sessionCheckIntervalSeconds * 1000);
	}

	/**
	 * cleaning the component by unsubscribing the subscriptions used
	 */
	ngOnDestroy() {
		// prevent memory leak when component destroyed
		// this.subscriptions.forEach(subscription => subscription.unsubscribe());
		// this.subscriptions = [];
	}
}
